import messages from "@/assets/images/messages.svg"
import { GoalPostType } from "@/types/goalPost"
import Loading from "@/components/UI/Loading"
import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import FavoriteIcon from "@mui/icons-material/Favorite"
import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect } from "react"
import { likePost } from "@/utils/post"
import { GoalCardProps } from "@/types/goalCard"
import { RootState } from "@/app/store"
import userImg from "@/assets/images/noUser.svg"
import moment from "moment"
import PostCard from "@/components/Cards/Post"
import heart from "@/assets/images/heart.svg"

interface GoalDetailsProps extends Omit<GoalCardProps, "uuid"> {
  selectedOption: string
  isFriendGoalPostList: boolean
}

interface GoalDetailsCardProps {
  goalPost: GoalPostType
}

const GoalDetails = ({
  goal,
  selectedOption,
  isFriendGoalPostList,
}: GoalDetailsProps) => {
  const dispatch = useDispatch()

  const [goalPostList, setGoalPostList] = useState<GoalPostType[] | []>([])
  const [loading, setLoading] = useState<boolean>(false)
  const fetchGoalPostList = async () => {
    setLoading(true)
    try {
      const response = await postRequest(
        `${getRoute(
          isFriendGoalPostList ? "friendGoalPostList" : "goalPostList",
        )}${selectedOption && `?filter=${selectedOption}`}`,
        {
          goal_uuid: goal.uuid,
        },
        dispatch,
        "goalPost",
      )
      setGoalPostList(response)
    } catch (err) {
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchGoalPostList()
  }, [selectedOption])

  return loading ? (
    <Loading />
  ) : goalPostList.length > 0 ? (
    goalPostList.map((goalPost) => (
      <GoalDetailsCard goalPost={goalPost} key={goalPost.uuid} />
    ))
  ) : (
    <div className="flex items-center justify-center">
      <p className="text-sm font-normal">No posts to show</p>
    </div>
  )
}

const GoalDetailsCard = ({ goalPost }: GoalDetailsCardProps) => {
  const dispatch = useDispatch()
  const {
    user: { image, first_name, last_name, uuid },
  } = useSelector((state: RootState) => state)
  const [showAnimation, setShowAnimation] = useState<boolean>(false)
  const [likeCount, setLikeCount] = useState(goalPost.total_likes)
  const [isLiked, setIsLiked] = useState<boolean>(goalPost.likes.includes(uuid))
  const [commentCount, setCommentCount] = useState(goalPost.total_comments ?? 0)
  const increaseLikeCount = () => {
    setLikeCount((prev) => prev + 1)
  }
  const decreaseLikeCount = () => {
    setLikeCount((prev) => prev - 1)
  }
  const icons = [
    {
      heading: likeCount,
      icon: (
        <FavoriteIcon
          className={`${isLiked ? "text-red-600" : "text-border-1"}`}
        />
      ),
      cursor: "pointer",
      action: async () =>
        await likePost(
          isLiked,
          setIsLiked,
          increaseLikeCount,
          decreaseLikeCount,
          goalPost.uuid,
          isLiked ? "unlike" : "like",
          dispatch,
          setShowAnimation,
        ),
    },
    {
      heading: commentCount,
      icon: <img src={messages} />,
      cursor: "pointer",
    },
  ]
  return (
    <PostCard
      customClass="shadow-none"
      icons={icons}
      setCommentCount={setCommentCount}
      uuid={goalPost.uuid}
    >
      <div className="px-5 pt-5 flex flex-col gap-4 border-t">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="w-9 h-9">
              <img
                src={image ? image : userImg}
                className="h-full w-full rounded-full"
              />
            </div>
            <div className="flex gap-1 flex-col">
              <p className="text-sm font-medium">
                {first_name} {last_name}
              </p>
              <p className="text-xs font-semibold text-grey-1">
                {moment(goalPost.date_created).format("DD MMM [at] h:mm a")}
              </p>
            </div>
          </div>
        </div>
        <p className="text-sm font-normal">{goalPost.description}</p>
        <div
          className={`grid ${
            goalPost.images.length !== 1 ? "grid-cols-2" : ""
          } gap-2 w-full relative`}
        >
          {goalPost.images.map((image) => (
            <img key={image} src={image} className="w-full h-auto" />
          ))}
          {goalPost.images && goalPost.images.length > 0 && (
            <div
              className={`absolute w-full h-full ${
                showAnimation ? "heart-animation !block" : "hidden"
              }`}
            >
              <img src={heart} className="w-full h-full" />
            </div>
          )}
        </div>
      </div>
    </PostCard>
  )
}

export default GoalDetails
