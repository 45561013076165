import { RootState } from "@/app/store"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Loading from "@/components/UI/Loading"
import CommentCard from "@/components/Cards/Comment"
import CommentForm from "@/components/Forms/Comment"
import SouthIcon from "@mui/icons-material/South"
import { getRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { CommentType } from "@/types/comment"
import NorthIcon from "@mui/icons-material/North"
interface CommentsProps {
  uuid: string
  setCommentCount: Dispatch<SetStateAction<number>>
}
const Comments = ({ uuid, setCommentCount }: CommentsProps) => {
  const dispatch = useDispatch()
  const {
    loading: { commentList: commentListLoading },
  } = useSelector((state: RootState) => state)
  const [showComments, setShowComments] = useState<boolean>(false)
  const [commentList, setCommentList] = useState<CommentType[]>([])
  const toggleShowComments = () => setShowComments((prev) => !prev)
  const fetchCommentList = async () => {
    try {
      const response = await getRequest(
        `${getRoute("commentList")}${uuid}/`,
        {},
        dispatch,
        "commentList",
      )
      setCommentList(response)
    } catch (err) {}
  }

  useEffect(() => {
    fetchCommentList()
  }, [])

  return (
    <>
      <CommentForm
        uuid={uuid}
        setCommentCount={setCommentCount}
        fetchCommentList={fetchCommentList}
      />
      <div
        className="flex items-center pl-5 cursor-pointer pb-5"
        onClick={toggleShowComments}
      >
        <p className="text-primary text-sm font-normal">
          {showComments ? "Hide" : "Load"} Comments
        </p>
        {showComments ? (
          <NorthIcon className="text-primary !text-sm" />
        ) : (
          <SouthIcon className="text-primary !text-sm" />
        )}
      </div>
      {showComments &&
        (commentListLoading ? (
          <div className="p-5">
            <Loading />
          </div>
        ) : commentList.length > 0 ? (
          <div className="flex flex-col gap-4 p-5">
            {commentList.map((comment) => (
              <CommentCard comment={comment} />
            ))}
          </div>
        ) : (
          <div className="p-10">
            <p className="text-sm font-normal text-center">
              No comments to show
            </p>
          </div>
        ))}
    </>
  )
}

export default Comments
