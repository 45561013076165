import { NavLinkType } from "@/types/navbar"
import friendGoalsActive from "@/assets/images/friendGoalsActive.svg"
import friendGoalsInactive from "@/assets/images/friendGoalsInactive.svg"
import myGoalsActive from "@/assets/images/myGoalsActive.svg"
import myGoalsInactive from "@/assets/images/myGoalsInactive.svg"
import myNetworkActive from "@/assets/images/myNetworkActive.svg"
import myNetworkInactive from "@/assets/images/myNetworkInactive.svg"
import { getRequest } from "../api"
import { getRoute } from "@/api/routes"
import { Dispatch, SetStateAction } from "react"
import { NotificationType } from "@/types/notification"

export const navLinks: NavLinkType[] = [
  {
    name: "Friend Goals",
    path: "/friendGoals",
    activeIcon: friendGoalsActive,
    inactiveIcon: friendGoalsInactive,
  },
  {
    name: "My Goals",
    path: "/goals",
    activeIcon: myGoalsActive,
    inactiveIcon: myGoalsInactive,
  },
  {
    name: "My Network",
    path: "/myNetwork",
    activeIcon: myNetworkActive,
    inactiveIcon: myNetworkInactive,
  },
  {
    name: "My Profile",
    path: "/profile",
    activeIcon: "",
    inactiveIcon: "",
  },
]

export const fetchNotifications = async (
  dispatch: Dispatch<any>,
  setNotifications: Dispatch<SetStateAction<NotificationType[] | []>>,
  setIsNewNotification: Dispatch<SetStateAction<boolean>>,
) => {
  try {
    const response = await getRequest(
      getRoute("notification"),
      {},
      dispatch,
      "notifications",
    )
    setNotifications(response)
    const hasUnreadNotification = response.some(
      (notification: NotificationType) => notification.is_read === false,
    )
    if (hasUnreadNotification) {
      setIsNewNotification(true)
    } else {
      setIsNewNotification(false)
    }
  } catch (err) {}
}
