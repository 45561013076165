import { GoalType } from "@/types/goal"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface GoalState {
  goalList: GoalType[] | []
  goalUUID: string
  privacy: boolean
}

const initialState: GoalState = {
  goalList: [],
  goalUUID: "",
  privacy: false,
}

export const goalSlice = createSlice({
  name: "goal",
  initialState,
  reducers: {
    updateGoalList: (state, action: PayloadAction<GoalType[]>) => {
      state.goalList = action.payload
    },
    updateGoalUUID: (state, action: PayloadAction<string>) => {
      state.goalUUID = action.payload
    },
    togglePrivacy: (state) => {
      state.privacy = !state.privacy
    },
  },
})

export const { updateGoalList, updateGoalUUID, togglePrivacy } =
  goalSlice.actions
export default goalSlice.reducer
