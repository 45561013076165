import Loading from "@/components/UI/Loading"
import { ReactNode } from "react"

interface buttonProps {
  name?: string | ReactNode
  type?: "button" | "submit" | "reset"
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
  customClass?: string
  onClick?: () => void
  icon?: ReactNode
  loaderColor?: string
  customTextClass?: string
  iconWrapperGap?: string
  loaderSize?: string
  iconOnlyInMobile?: boolean
}
const Button = ({
  name,
  type = "button",
  disabled,
  loading,
  fullWidth,
  customClass,
  onClick,
  icon,
  loaderColor,
  customTextClass,
  iconWrapperGap = "gap-0.5 md:gap-2",
  loaderSize,
  iconOnlyInMobile = false,
}: buttonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${customClass} border p-3 rounded-50px bg-blue ${
        fullWidth && "w-full"
      }`}
      onClick={onClick ?? undefined}
    >
      {loading ? (
        <Loading color={loaderColor} size={loaderSize} />
      ) : (
        <div className={`flex items-center ${iconWrapperGap} justify-center`}>
          {icon}
          <p
            className={`font-normal ${
              iconOnlyInMobile ? "hidden md:block" : "block"
            }  text-sm text-white ${customTextClass}`}
          >
            {name}
          </p>
        </div>
      )}
    </button>
  )
}

export default Button
