import { getRoute } from "@/api/routes"
import { getRequest } from "@/api/index"
import { updateGoalList } from "@/slice/goal"
import { updateFriendRequestList } from "@/slice/friend"

export const fetchAllGoals = async (
  dispatch: React.Dispatch<any>,
  uuid?: string,
  extendedUrl?: string,
  friendGoal?: boolean,
) => {
  try {
    const response = await getRequest(
      friendGoal
        ? `${getRoute("friendGoalList")}${extendedUrl}`
        : uuid
        ? `${getRoute("goals")}${extendedUrl?.split("/")[1]}`
        : getRoute("goals"),
      {},
      dispatch,
      "goalList",
    )
    dispatch(updateGoalList(uuid ? [response] : response))
  } catch (err) {
    dispatch(updateGoalList([]))
  }
}

export const fetchFriendRequestList = async (dispatch: React.Dispatch<any>) => {
  try {
    const response = await getRequest(
      getRoute("friendRequestList"),
      {},
      dispatch,
      "friendRequestList",
    )
    dispatch(updateFriendRequestList(response))
  } catch (err) {}
}
