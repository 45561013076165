import { postRequest } from "@/api/index"
import { getRoute } from "@/api/routes"
import { RootState } from "@/app/store"
import FriendGoalsCard from "@/components/Cards/FriendGoals"
import CardsContainer from "@/components/CardsContainer"
import FriendList from "@/components/FriendList"
import Loading from "@/components/UI/Loading"
import { updateLayout } from "@/slice/layout"
import { FeedType } from "@/types/feed"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

const FriendGoalsScreen = () => {
  const dispatch = useDispatch()
  const { feed: feedLoading } = useSelector((state: RootState) => state.loading)
  const [feedList, setFeedList] = useState<FeedType[] | []>([])

  const fetchFeed = async () => {
    await postRequest(getRoute("feed"), {}, dispatch, "feed")
      .then((res) => setFeedList(res))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    fetchFeed()
  }, [])

  useEffect(() => {
    if (feedLoading) {
      dispatch(
        updateLayout({
          leftColumnWidthClass: "w-full",
          rightColumnChildren: <></>,
        }),
      )
    } else {
      dispatch(
        updateLayout({
          leftColumnWidthClass: "",
          rightColumnChildren: <FriendList />,
        }),
      )
    }
  }, [feedLoading])

  return (
    <div className="w-full overflow-y-scroll scrollbar-hide">
      {feedLoading ? (
        <Loading />
      ) : feedList.length > 0 ? (
        <CardsContainer>
          {feedList.map((feed) => (
            <FriendGoalsCard key={feed.uuid} friendGoal={feed} />
          ))}
        </CardsContainer>
      ) : (
        <div className="h-full justify-center items-center flex w-full lg:max-w-3xl lg:w-[596px]">
          <p>Nothing to show</p>
        </div>
      )}
    </div>
  )
}

export default FriendGoalsScreen
