import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "@/app/store"
import Loading from "@/components/UI/Loading"
import GoalCard from "@/components/Cards/Goal"
import { fetchAllGoals } from "@/utils/updateRedux"
import CardsContainer from "@/components/CardsContainer"
import { updateLayout } from "@/slice/layout"
import AnalyticsCard from "@/components/Cards/Analytics"
import { useParams } from "react-router-dom"
import { Fab } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import CustomModal from "@/components/UI/Modal"
import GoalForm from "@/components/Forms/Goal"

const GoalScreen = () => {
  const { userUuid, goalUuid } = useParams()
  const dispatch = useDispatch()
  const {
    loading: { goalList: goalListLoading },
    goal: { goalList: goals },
    user: { uuid },
    notifications: { selectedNotificationType },
  } = useSelector((state: RootState) => {
    return state
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showCompleted, setShowCompleted] = useState<boolean>(false)
  const toggleModal = () => setIsOpen((prev) => !prev)
  useEffect(() => {
    fetchAllGoals(
      dispatch,
      userUuid,
      `${userUuid}/${goalUuid}`,
      selectedNotificationType === "friend",
    )
  }, [userUuid])

  useEffect(() => {
    if (goalListLoading) {
      dispatch(
        updateLayout({
          leftColumnWidthClass: "w-full",
          rightColumnChildren: <></>,
        }),
      )
    } else {
      dispatch(
        updateLayout({
          leftColumnWidthClass: "w-full lg:w-auto",
          rightColumnChildren: !userUuid && <AnalyticsCard />,
        }),
      )
    }
  }, [goalListLoading])

  return (
    <div className="w-full overflow-y-scroll scrollbar-hide">
      <CustomModal
        isOpen={isOpen}
        onClose={() => {
          toggleModal()
          setShowCompleted(false)
        }}
        title="ADD NEW GOAL"
        showHeader={!showCompleted}
      >
        <GoalForm
          onClose={() => {
            toggleModal()
            setShowCompleted(false)
          }}
          setShowCompleted={setShowCompleted}
          showCompleted={showCompleted}
        />
      </CustomModal>
      {!isOpen && (
        <Fab
          color="primary"
          size="small"
          className="!absolute bottom-24 right-4 lg:!hidden !bg-parrotGreen"
          onClick={toggleModal}
        >
          <AddIcon />
        </Fab>
      )}
      {goalListLoading ? (
        <Loading />
      ) : goals.length > 0 ? (
        <CardsContainer>
          {goals.map((goal) => (
            <GoalCard key={goal.uuid} goal={goal} uuid={userUuid} />
          ))}
        </CardsContainer>
      ) : (
        <div className="h-full justify-center items-center flex w-full lg:max-w-3xl lg:w-[596px]">
          <p>Nothing to show</p>
        </div>
      )}
    </div>
  )
}

export default GoalScreen
